import { getFilterDate } from './getFilterDate';

export const getTagByDate = (top: string, tag: string, page: number) => {
  if (top === 'allTime') {
    return `/posts?\n
filters[isMainPage]=true&\n
filters[isHidden]=false&\n
filters[tags][name]=${tag}&\n
filters[isHidden]=false&\n
sort[0]=likes:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=author.avatar&\n
populate[8]=categories.icon&\n
populate[9]=userBadgeIcon`;
  }

  const date = getFilterDate(top);

  return `/posts?\n
filters[isMainPage]=true&\n
filters[isHidden]=false&\n
filters[tags][name]=${tag}&\n
filters[publishedAt][$gte]=${date}&\n
filters[isHidden]=false&\n
sort[0]=likes:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=author.avatar&\n
populate[8]=categories.icon&\n
populate[9]=userBadgeIcon`;
};
