import { getFilterDate } from './getFilterDate';

export const getCategoryByDate = (categoryId: string | string[] | undefined, top: string, page: number) => {
  if (top === 'allTime') {
    return `/posts?\n
filters[isMainPage]=true&\n
filters[isHidden]=false&\n
filters[categories][id][$in]=${categoryId}&\n
sort[0]=publishedAt:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=executeAt&\n
populate[8]=author.avatar&\n
populate[9]=pollQuestion.pollOptions&\n
populate[10]=categories.icon&\n
populate[11]=userBadgeIcon`;
  }

  const date = getFilterDate(top);

  return `/posts?\n
filters[isMainPage]=true&\n
filters[isHidden]=false&\n
filters[categories][id][$in]=${categoryId}&\n
filters[publishedAt][$gte]=${date}&\n
sort[0]=publishedAt:desc&\n
pagination[page]=${page}&\n
pagination[pageSize]=8&\n
populate[0]=postCoverPicture&\n
populate[1]=socialPicture&\n
populate[2]=relatedPosts&\n
populate[3]=author&\n
populate[4]=tags&\n
populate[5]=type&\n
populate[6]=postVideo&\n
populate[7]=executeAt&\n
populate[8]=author.avatar&\n
populate[9]=pollQuestion.pollOptions&\n
populate[10]=categories.icon&\n
populate[11]=userBadgeIcon`;
};
